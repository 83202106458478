// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Competencia_Circulo__V3plD{
    width: 100px;
    height: 100px;
    background-color: rgb(234, 171, 28);
    border-radius: 100px;
}
.Competencia_Container__HxmYp{
    animation: 0.65s ease 0s 1 normal none running forwards;
    justify-self: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: top left;
    border-radius: 0px;
    margin-bottom: 0px;
    min-height: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.Competencia_ContCirculo__-F2cO {
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Competencias/Competencia/Competencia.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mCAAmC;IACnC,oBAAoB;AACxB;AACA;IACI,uDAAuD;IACvD,oBAAoB;IACpB,kBAAkB;IAClB,kCAAkC;IAClC,yBAAyB;IACzB,6BAA6B;IAC7B,6BAA6B;IAC7B,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".Circulo{\n    width: 100px;\n    height: 100px;\n    background-color: rgb(234, 171, 28);\n    border-radius: 100px;\n}\n.Container{\n    animation: 0.65s ease 0s 1 normal none running forwards;\n    justify-self: center;\n    text-align: center;\n    background-color: rgba(0, 0, 0, 0);\n    background-repeat: repeat;\n    background-attachment: scroll;\n    background-position: top left;\n    border-radius: 0px;\n    margin-bottom: 0px;\n    min-height: 0px;\n    padding-top: 15px;\n    padding-bottom: 15px;\n    padding-left: 15px;\n    padding-right: 15px;\n}\n\n.ContCirculo {\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Circulo": `Competencia_Circulo__V3plD`,
	"Container": `Competencia_Container__HxmYp`,
	"ContCirculo": `Competencia_ContCirculo__-F2cO`
};
export default ___CSS_LOADER_EXPORT___;
