// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Presentacion_Container__xRcAQ{
    padding-bottom: 120px;
    padding-top: 120px;
    background-color: rgb(239, 241, 248);
}

.Presentacion_Titulo__gnIG-{
    color: rgb(43, 45, 58);
    font-size: 52px;
    font-weight: 900;
    font-family: "Open Sans";
    font-style: normal;
    letter-spacing: 0px;
    line-height: 66px;
    margin-bottom: 45px;
    text-align: center;
    background-color: transparent;
    border-color: rgb(206, 234, 101);
    border-width: 1px;
}

.Presentacion_Texto__hBVeI{
    color: rgb(137, 141, 158);
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
    font-family: "Open Sans";
    padding: 0% 10%;
}

@media(min-width: 200px){
    .Presentacion_Texto__hBVeI{
        font-size: 14px;
    }
    .Presentacion_Titulo__gnIG-{
        font-size: 40px;
    }

}


@media(min-width: 400px){
    .Presentacion_Texto__hBVeI{
        font-size: 18px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Presentacion/Presentacion.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,6BAA6B;IAC7B,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;;AAEJ;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Container{\n    padding-bottom: 120px;\n    padding-top: 120px;\n    background-color: rgb(239, 241, 248);\n}\n\n.Titulo{\n    color: rgb(43, 45, 58);\n    font-size: 52px;\n    font-weight: 900;\n    font-family: \"Open Sans\";\n    font-style: normal;\n    letter-spacing: 0px;\n    line-height: 66px;\n    margin-bottom: 45px;\n    text-align: center;\n    background-color: transparent;\n    border-color: rgb(206, 234, 101);\n    border-width: 1px;\n}\n\n.Texto{\n    color: rgb(137, 141, 158);\n    font-size: 20px;\n    letter-spacing: 2px;\n    text-align: center;\n    font-family: \"Open Sans\";\n    padding: 0% 10%;\n}\n\n@media(min-width: 200px){\n    .Texto{\n        font-size: 14px;\n    }\n    .Titulo{\n        font-size: 40px;\n    }\n\n}\n\n\n@media(min-width: 400px){\n    .Texto{\n        font-size: 18px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `Presentacion_Container__xRcAQ`,
	"Titulo": `Presentacion_Titulo__gnIG-`,
	"Texto": `Presentacion_Texto__hBVeI`
};
export default ___CSS_LOADER_EXPORT___;
