// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Redes_Redes__z8n5l{
    padding-bottom: 120px;
    padding-top: 50px;

    background-color: rgb(28, 30, 41);
    color: whitesmoke;
    width: 100%;
    text-align: center;
}


.Redes_Titulo__ajFps{
    color: #EBEEF1;
    font-size: 40px;

    text-align: center;
    font-family: "Open Sans";
}

.Redes_Circulo__a5Dz1{
    background-color: #eaab1c;
    text-decoration: none;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 8px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
}


@media(min-width: 800px){
    .Redes_Titulo__ajFps{
        font-size: 52px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Redes/Redes.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;;IAEjB,iCAAiC;IACjC,iBAAiB;IACjB,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI,cAAc;IACd,eAAe;;IAEf,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Redes{\n    padding-bottom: 120px;\n    padding-top: 50px;\n\n    background-color: rgb(28, 30, 41);\n    color: whitesmoke;\n    width: 100%;\n    text-align: center;\n}\n\n\n.Titulo{\n    color: #EBEEF1;\n    font-size: 40px;\n\n    text-align: center;\n    font-family: \"Open Sans\";\n}\n\n.Circulo{\n    background-color: #eaab1c;\n    text-decoration: none;\n    display: inline-block;\n    width: 100px;\n    height: 100px;\n    margin: 8px;\n    line-height: 40px;\n    border-radius: 50%;\n    text-align: center;\n}\n\n\n@media(min-width: 800px){\n    .Titulo{\n        font-size: 52px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Redes": `Redes_Redes__z8n5l`,
	"Titulo": `Redes_Titulo__ajFps`,
	"Circulo": `Redes_Circulo__a5Dz1`
};
export default ___CSS_LOADER_EXPORT___;
