// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Imagen_Imagen__-96IM {
    height: auto;
    width: 100%;
    filter: brightness(0.45);
    display: block;
}


.Imagen_HeaderImagen__kfg\\+X{
    width: 100%;
    top: 26%;
    text-align: center;
    z-index: 11;
}

.Imagen_Contenido__Ls3LT{
    display: block;
}

@media(min-width: 400px){
    .Imagen_Imagen__-96IM{
        width: 100%;
    }

    .Imagen_HeaderImagen__kfg\\+X{
        position: relative;
    }
}

@media(min-width: 800px){
    .Imagen_Imagen__-96IM{
        width: 100%;
    }

    .Imagen_HeaderImagen__kfg\\+X{
        position: relative;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Imagen/Imagen.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,cAAc;AAClB;;;AAGA;IACI,WAAW;IACX,QAAQ;IACR,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,kBAAkB;IACtB;AACJ","sourcesContent":[".Imagen {\n    height: auto;\n    width: 100%;\n    filter: brightness(0.45);\n    display: block;\n}\n\n\n.HeaderImagen{\n    width: 100%;\n    top: 26%;\n    text-align: center;\n    z-index: 11;\n}\n\n.Contenido{\n    display: block;\n}\n\n@media(min-width: 400px){\n    .Imagen{\n        width: 100%;\n    }\n\n    .HeaderImagen{\n        position: relative;\n    }\n}\n\n@media(min-width: 800px){\n    .Imagen{\n        width: 100%;\n    }\n\n    .HeaderImagen{\n        position: relative;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Imagen": `Imagen_Imagen__-96IM`,
	"HeaderImagen": `Imagen_HeaderImagen__kfg+X`,
	"Contenido": `Imagen_Contenido__Ls3LT`
};
export default ___CSS_LOADER_EXPORT___;
