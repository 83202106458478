// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Proyecto_FlipCard__2dCR9 {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .Proyecto_FlipCardInner__FYhGB {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: rgb(239, 241, 248);
  }
  
  .Proyecto_FlipCard__2dCR9:hover .Proyecto_FlipCardInner__FYhGB {
    transform: rotateY(180deg);
  }
  
  .Proyecto_FlipCardFront__D\\+\\+U6 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
  }
  
  .Proyecto_FlipCardBack__4TJQA{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .Proyecto_FlipCardFront__D\\+\\+U6 {
    color: black;
  }
  
  .Proyecto_FlipCardBack__4TJQA {
    background-color: rgb(234, 171, 28);
    color: white;
    transform: rotateY(180deg);
    cursor: pointer;
  }

  .Proyecto_Imagen__4Wc3E{
    width:100%;
  }

  .Proyecto_Texto__Oj9Wa{
    text-align: justify;
    padding: 0px 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Proyectos/Proyecto/Proyecto.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,4BAA4B;IAC5B,uCAAuC;IACvC,oCAAoC;EACtC;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,2BAA2B;EAC7B;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,mCAAmC;IACnC,YAAY;IACZ,0BAA0B;IAC1B,eAAe;EACjB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;EACnB","sourcesContent":[".FlipCard {\n    background-color: transparent;\n    width: 300px;\n    height: 300px;\n    perspective: 1000px;\n    cursor: pointer;\n  }\n  \n  .FlipCardInner {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    transition: transform 0.6s;\n    transform-style: preserve-3d;\n    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);\n    background-color: rgb(239, 241, 248);\n  }\n  \n  .FlipCard:hover .FlipCardInner {\n    transform: rotateY(180deg);\n  }\n  \n  .FlipCardFront {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    backface-visibility: hidden;\n  }\n  \n  .FlipCardBack{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    backface-visibility: hidden;\n  }\n\n  .FlipCardFront {\n    color: black;\n  }\n  \n  .FlipCardBack {\n    background-color: rgb(234, 171, 28);\n    color: white;\n    transform: rotateY(180deg);\n    cursor: pointer;\n  }\n\n  .Imagen{\n    width:100%;\n  }\n\n  .Texto{\n    text-align: justify;\n    padding: 0px 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlipCard": `Proyecto_FlipCard__2dCR9`,
	"FlipCardInner": `Proyecto_FlipCardInner__FYhGB`,
	"FlipCardFront": `Proyecto_FlipCardFront__D++U6`,
	"FlipCardBack": `Proyecto_FlipCardBack__4TJQA`,
	"Imagen": `Proyecto_Imagen__4Wc3E`,
	"Texto": `Proyecto_Texto__Oj9Wa`
};
export default ___CSS_LOADER_EXPORT___;
