// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Competencias_Container__DcPGI {

    padding-bottom: 120px;
    padding-top: 120px;
    padding-left: 4%;
    padding-right: 4%;
  }


  @media(min-width: 800px){
    .Competencias_Container__DcPGI{
      display: grid;
      grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
      grid-template-rows: 1fr;
    }

  }
`, "",{"version":3,"sources":["webpack://./src/components/Competencias/Competencias.module.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB;;;EAGA;IACE;MACE,aAAa;MACb,kDAAkD;MAClD,uBAAuB;IACzB;;EAEF","sourcesContent":[".Container {\n\n    padding-bottom: 120px;\n    padding-top: 120px;\n    padding-left: 4%;\n    padding-right: 4%;\n  }\n\n\n  @media(min-width: 800px){\n    .Container{\n      display: grid;\n      grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;\n      grid-template-rows: 1fr;\n    }\n\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `Competencias_Container__DcPGI`
};
export default ___CSS_LOADER_EXPORT___;
